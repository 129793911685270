// @font-face {
//   font-family: 'regular-en';
//   src: url(../fonts/en/Roboto-Regular.ttf);
// }

// @font-face {
//   font-family: 'bold-en';
//   src: url(../fonts/en/Roboto-Bold.ttf);
// }

// :root {
//   --main-color: #dd283b;
//   --regular: 'regular-en';
//   --bold: 'bold-en';

//   :lang(ar),
//   :lang(kr) {
//   }
// }

// /* Box sizing rules */
// *,
// *::before,
// *::after {
//   box-sizing: border-box;
//   font-family: var(--regular);
// }

// /* Remove default margin */
// body,
// h1,
// h2,
// h3,
// h4,
// p,
// figure,
// blockquote,
// dl,
// dd {
//   margin: 0;
// }

// /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
// ul[role='list'],
// ol[role='list'] {
//   list-style: none;
// }

// /* Set core root defaults */
// html:focus-within {
//   scroll-behavior: smooth;
// }

// /* Set core body defaults */
// body {
//   min-height: 100vh;
//   text-rendering: optimizeSpeed;
//   line-height: 1.5;
// }

// /* A elements that don't have a class get default styles */
// a:not([class]) {
//   text-decoration-skip-ink: auto;
// }

// /* Make images easier to work with */
// img,
// picture {
//   max-width: 100%;
//   display: block;
// }

// /* Inherit fonts for inputs and buttons */
// input,
// button,
// textarea,
// select {
//   font: inherit;
// }

// /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
// @media (prefers-reduced-motion: reduce) {
//   html:focus-within {
//     scroll-behavior: auto;
//   }

//   *,
//   *::before,
//   *::after {
//     animation-duration: 0.01ms !important;
//     animation-iteration-count: 1 !important;
//     transition-duration: 0.01ms !important;
//     scroll-behavior: auto !important;
//   }
// }

@font-face {
  font-family: 'regular-en';
  src: url(../fonts/en/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'bold-en';
  src: url(../fonts/en/Roboto-Bold.ttf);
}

:root {
  --main-color: #dd283b;
  --regular: 'regular-en';
  --bold: 'bold-en';

  :lang(ar),
  :lang(kr) {
  }
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: var(--regular);
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.logo-container {
  width: 180px;
  height: 90px;
  position: relative;
  margin: auto;
}
.logo {
  height: 100px;
  width: 100%;
  object-fit: contain;
}

.header-layout {
  padding: 0;
  background: white;
}

.menu-header {
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 95%;
}

.anticon.trigger {
  margin-left: 2rem;
}
.content-routes {
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px;
  background-color: white;
}

.sidebar {
  overflow-y: scroll;
  height: 100vh;
}
.anticon {
  margin-right: 4px;
}

.ant-menu-title-content {
  margin: 0 !important;
}

::-webkit-scrollbar {
  width: 4px; /* width of the entire scrollbar */
  background-color: #18c59f;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color); /* color of the scroll thumb */
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.right-quill {
  .ql-editor {
    direction: rtl;
    text-align: right;
    li:not(.ql-direction-rtl)::before {
      margin-right: unset !important;
      margin-left: 0.1em;
    }
    li:not(.ql-direction-rtl) {
      padding-right: 1.5em;
    }
    ul,
    ol {
      padding-right: 1.5em;
    }
  }
}

.ql-editor {
  min-height: 200px;
}
